import React from 'react';
import {observer} from 'mobx-react';


import styled from 'styled-components'

const PropertyDisplay = styled.div`
	width: 100%;
	min-height: 20px;
	margin-bottom: 8px;
`

const LoadingBG = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #cccccc;
	z-index: -1;
`

const Display = styled.img`
	cursor: pointer;
	width: 100%;
`

interface LocationProps {
	property?: any
}



@observer
export class Location extends React.Component<LocationProps> {

	gotoStore = () => {
		const property = this.props.property!;
		localStorage.setItem('propertyId', property.id);
		window.location.href = `https://getawosh.myshopify.com/collections/all?sort_by=price-ascending#${property.id}`;
	}

	render(){
		const property = this.props.property!;
		return (
			<PropertyDisplay>
				<LoadingBG/>
				<Display onClick={this.gotoStore} src={property.src}/>
			</PropertyDisplay>
		);
	}
}