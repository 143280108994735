import React from 'react';
import {observer, inject} from 'mobx-react';
import styled from 'styled-components'
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { MainStore } from '../store/models/MainStore';
import { Property } from '../store/models/Property';
import { Location } from './Location';


const PropertyList = styled.div`
	max-width: 440px;
	margin: 0 auto;
`

const Head = styled.div`
	padding: 24px 0 ;
	font-size: 16px;
	color: #727373;
	text-align: center;
	font-weight: bold;
	/* #26c0f0 */
`

interface LocationsProps {
	mainStore?: MainStore
}

const initialState = {
	selectedProperty: 'asdf'
};
type State = Readonly<typeof initialState>

@inject('mainStore')
@observer
export class Locations extends React.Component<LocationsProps> {

	readonly state: State = initialState

	constructor(props: LocationsProps){
		super(props)

		const store = props.mainStore!;

		// @ts-ignore
		this.state.selectedProperty = localStorage.getItem('propertyId');

		navigator.geolocation.getCurrentPosition((position) => {
			store.setLocation(position.coords.latitude, position.coords.longitude);
		});
	}

	componentDidMount(){
		setTimeout(() => window.scrollTo(0, 0), 500);
	}

	render(){
		const {
			hasUserLocation,
			propertiesByDistance,
			properties,
			getPropertyById
		} = this.props.mainStore!;
		const props = hasUserLocation ? propertiesByDistance : properties;
		const selection = getPropertyById(this.state.selectedProperty);
		return (
			<PropertyList>
				{selection && (
					<React.Fragment>
						<Head>YOUR BUILDING</Head>
						<Location key={selection.id} property={selection}/>
					</React.Fragment>
				)}
				<Head>CHOOSE YOUR BUILDING</Head>
				{props.filter((prop:Property) => prop.id !== this.state.selectedProperty).map((prop:Property) => <Location key={prop.id} property={prop}/>)}
			</PropertyList>
		);
	}
}