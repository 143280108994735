import React from 'react';
import {observer, Provider} from 'mobx-react';

import { BrowserRouter as Router, Route } from "react-router-dom";
import { Locations } from './Locations';
import { store } from '../store';

@observer
export class Main extends React.Component {
	render(){
		return (
			<Provider mainStore={store}>
				<Router>
					<Route exact path="/" component={Locations}/>
				</Router>
			</Provider>
		);
	}
}