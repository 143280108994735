

//import React, { Component } from 'react';
import {
	observable,
	computed,
	action
} from "mobx"; //{ toJS }

import {sortBy} from 'lodash';

import {Property} from './Property';
import { distance } from "../../helpers/distance";

export interface IMainStore {
	message: string;
	isUserLoggedIn: boolean;
	userLocation: object;
	hasUserLocation: boolean;
	properties: Property[];
	propertiesByDistance: Property[];
	setLocation(lat: number, long: number): void
}

export class MainStore implements IMainStore {
	@observable message = 'Hello World';

	@observable isUserLoggedIn:boolean = true;

	@observable userLocation = {lat: 0, long: 0};

	@action setLocation = (lat: number, long: number) => {
		this.userLocation.lat = lat;
		this.userLocation.long = long;
	}

	@computed get hasUserLocation():boolean {
		return this.userLocation.lat !== 0 && this.userLocation.long !== 0;
	}

	@computed get propertiesByDistance(){
		var propDistances = this.properties.map((p, i) => ({index: i, dist: distance(this.userLocation.lat, this.userLocation.long, p.lat, p.long)}));


		const sortedProperties = sortBy(propDistances, ['dist'], ['asc']).map(p => this.properties[p.index]);


		return sortedProperties;
	}

	getPropertyById = (id: string): Property | undefined => {
		return this.properties.find(p => p.id === id);
	}


	bookingFormDictionary = {
		'1600vine': '1600 VINE',
		'eastown': 'EASTOWN',
		'argylehouse': 'Argyle House',
		'sunsetvinetower': 'Sunset Vine Tower',
		'alina': 'Alina',
		'atelier': 'Atelier',
		'8thandhope': '8TH+HOPE',
		'717olympic': '717 Olympic',
		'1111wilshire': '1111 Wilshire',
		'eighth&grand': 'Eighth & Grand',
		'theemerson': 'The Emerson',
		'1100wilshire': '1100 Wilshire',
		'packardlofts': 'Packard Lofts',
		'wren': 'WREN',
		'sofia': 'SOFIA',
		'1000grand': '1000 Grand by Windsor',
		'southpark': 'South Park by Windsor',
		'themint': 'The Mint',
		'residencesatthew': 'The Residences at The W',
		'aven': 'AVEN',
		'wilshirevalencia': 'WilshireValencia',
		'griffinspring': 'The Griffin on Spring',
		'gracespring': 'The Grace on Spring',
		'hope+flower':'Hope+Flower',
		'thewalnutbuilding': 'The Walnut Building',
		'thea':'thea',
		'bedtla': 'Be DTLA',
		'parkfifthtower': 'Park Fifth Tower',
		'olympicbywindsor': 'Olympic by Windsor',
		'avant': 'AVANT'
	}

	//these need to match the keys in booking-form.liquid, copied above
	@observable properties = [
		new Property('1600vine', '1600 VINE', '1600 VINE', 34.1003784, -118.32605860000001),
		new Property('eastown', 'EASTOWN', 'EASTOWN', 34.10216389999999, -118.32455920000001),
		new Property('argylehouse', 'Argyle House', 'Argyle House', 34.1034002, -118.32564769999999),
		new Property('sunsetvinetower', 'Sunset Vine Tower', 'Sunset Vine Tower', 34.0976033, -118.3264572),
		new Property('alina', 'Alina', 'Alina', 34.04558180000001, -118.2624381),
		new Property('atelier', 'Atelier', 'Atelier', 34.0450682, -118.25739750000002),
		new Property('8thandhope', '8TH+HOPE', '8TH+HOPE', 34.0464709, -118.25951459999999),
		new Property('717olympic', '717 Olympic', '717 Olympic', 34.0450852, -118.263306),
		new Property('1111wilshire', '1111 Wilshire', '1111 WILSHIRE', 34.0528158, -118.2637628),
		new Property('eighth&grand', 'Eighth & Grand', 'Eighth & Grand', 34.0463729, -118.25722029999997),
		new Property('theemerson', 'The Emerson', 'The Emerson', 34.0540387, -118.2511548),
		new Property('1100wilshire', '1100 Wilshire', '1100 Wilshire', 34.0521309, -118.2635621),
		new Property('packardlofts', 'Packard Lofts', 'Packard Lofts', 34.0431569, -118.26189),
		new Property('wren', 'WREN', 'WREN', 34.0384974, -118.2627579),
		new Property('sofia', 'SOFIA', 'SOFIA', 34.0534129, -118.26337460000002),
		new Property('1000grand', '1000 Grand by Windsor', '1000 Grand', 34.0425216, -118.26073150000002),
		new Property('southpark', 'South Park by Windsor', 'South Park by Windsor', 34.0423767, -118.25824869999997),
		new Property('themint', 'The Mint', 'The Mint', 34.0544079, -118.2635573),
		new Property('residencesatthew', 'The Residences at The W', 'w', 34.1009554, -118.3258303),
		new Property('aven', 'AVEN', 'AVEN', 34.0404474, -118.2646832),
		new Property('wilshirevalencia', 'WilshireValencia', 'WilshireValencia', 34.0552013, -118.2707591),
		new Property('griffinspring', 'The Griffin on Spring', 'The Griffin on Spring', 34.0434744, -118.2537075),
		new Property('gracespring', 'The Grace on Spring', 'The Grace on Spring', 34.0525056, -118.2646272),
		new Property('hope+flower', 'Hope+Flower', 'Hope+Flower', 34.0414343, -118.2656183),
		new Property('thewalnutbuilding', 'The Walnut Building', 'The Walnut Building', 34.0349341, -118.2346156),
		new Property('thea', 'thea', 'thea', 34.0484345, -118.2636893),
		new Property('bedtla', 'Be DTLA', 'Be DTLA', 34.0534129, -118.26337460000002),
		new Property('parkfifthtower', 'Park Fifth Tower', 'Park Fifth Tower', 34.0511555, -118.261195),
		new Property('olympicbywindsor', 'Olympic by Windsor', 'Olympic by Windsor', 34.0425885, -118.2587846),
		new Property('avant', 'AVANT', 'AVANT', 34.0383481, -118.3042595)
	];
}
