//import React, { Component } from 'react';
import {
	observable,
	computed
} from "mobx"; //{ toJS }

export interface IProperty {
	id: string
	name: string
	img: string
	coords: object
	src: string
}

export class Property {

	constructor(id: string, name: string, img: string, lat?: number, long?: number){
		this.id = id;
		this.name = name;
		this.img = img;
		this.lat = lat || 0;
		this.long = long || 0;
	}

	@observable id = '';
	@observable name = '';
	@observable img = '';
	@observable coords = {lat: 0, long: 0};
	@observable lat = 0;
	@observable long = 0;

	@computed get src(){
		return `/media/${this.img}.png`;
	}
}
